// src/utils/auth.ts

import axios from 'axios';

/**
 * 向后端发送请求以验证JWT令牌的合法性
 * @param {string} token - JWT令牌
 * @returns {Promise<boolean>} - 异步返回令牌是否有效
 */
export async function isTokenLegal(token: string | null): Promise<boolean> {
    if (!token) {
        return false;  // 如果没有令牌，直接返回false
    }

    // 使用环境变量中的API URL
    const apiUrl = process.env.VUE_APP_API_URL;
    try {
        // 构建完整的请求URL
        const url = `${apiUrl}/flicker/token_verify`;
        const response = await axios.post(url, { token });

        // 假设后端返回的数据结构如 { status: 'success' } 或 { status: 'error' }
        return response.data.status === 'success';
    } catch (error) {
        // 如果请求失败或后端返回错误状态，视为非法令牌
        return false;
    }
}
