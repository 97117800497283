import { createStore } from 'vuex';

export default createStore({
  state: {
    isLoggedIn: false,
    showLoginModal: false,
    isLogin: true, // true indicates 'Login' mode, false for 'Register' mode
    username: '',
    form: {
      username: '',
      password: '',
      email: '',
      country: ''
    },
  },
  mutations: {
    TOGGLE_LOGIN_MODAL(state, value) {
      state.showLoginModal = value !== undefined ? value : !state.showLoginModal;
    },
    SET_LOGIN_MODE(state, isLogin) {
      state.isLogin = isLogin;
    },
    SET_LOGIN_STATUS(state, status) {
      state.isLoggedIn = status;
    },
    SET_USERNAME(state, username) {
      state.username = username;
    },
    RESET_FORM(state) {
      state.form = {
        username: '',
        password: '',
        email: '',
        country: ''
      };
    }
  },
  actions: {
    toggleLoginModal({ commit }, value) {
      commit('TOGGLE_LOGIN_MODAL', value);
    },
    setLoginMode({ commit }, isLogin) {
      commit('SET_LOGIN_MODE', isLogin);
    },
    setLoginStatus({ commit }, status) {
      commit('SET_LOGIN_STATUS', status);
    },
    setUsername({ commit }, username) {
      commit('SET_USERNAME', username);
    },
    resetForm({ commit }) {
      commit('RESET_FORM');
    }
  }
});
