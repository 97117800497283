import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import { isTokenLegal } from './utils/auth';

const app = createApp(App);

router.beforeEach(async (to, from, next) => {
    const token = localStorage.getItem('userToken');

    // 检查是否有 serverOnly 的 meta 标记
    if (to.matched.some(record => record.meta.serverOnly)) {
        // 检查 referer 或者其他条件来判断是否是服务器跳转
        const referer = document.referrer;

        // 如果 referer 为空或者不符合条件，则重定向到首页
        if (!referer || !referer.startsWith('https://flicker.buzz')) {
            return next({ path: '/' });
        }

    }

    if (to.matched.some(record => record.meta.requiresAuth) && !token) {
        // 如果路由需要授权且用户未登录，重定向到根路径
        next({ path: '/' });
    } 
    else if (to.matched.some(record => record.meta.requiresToken)) {
        const valid = await isTokenLegal(token);
        if (!valid) {
            next({ path: '/' }); // 如果令牌无效，重定向
        } else {
            next(); // 允许通过
        }
    }
    else {
        next(); // 否则允许通过
    }
});


app.use(store);
app.use(router);
app.use(ElementPlus);

app.mount('#app');

