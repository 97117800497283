<template>
  <div class="ai-slider-page">
    <header>
      <span class="logo">
        <span class="iconfont icon-react"></span>
        <span class="logo-name">Flicker</span>
      </span>
      <div v-if="isLoggedIn" class="user-info">

        <span @click="stripeCheckout" 
              v-bind:title="username" 
              style="cursor: pointer; font-family: 'alibaba-heavy', sans-serif; display: inline-block; max-width: 350px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
          Welcome! {{ username }}
        </span>

        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            <el-icon class="el-icon--right">
              <arrow-down />
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="profile">Profile</el-dropdown-item>
              <el-dropdown-item command="settings">Settings</el-dropdown-item>
              <el-dropdown-item command="logout">Logout</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <div v-else class="btn-login" 
            @click="toggleLoginModal" 
            style="font-family: 'alibaba-heavy', sans-serif;">
        Login
      </div>
    </header>
    
    <div id="app">
      <router-view></router-view>
    </div>

    <footer v-if="isLoggedIn" class="footer">
      <span class="footer-text" @click="centerDialogVisible = true">Remaining Uses: {{ num }}</span>
      <img src="@/assets/images/question.png" alt="Question Icon" class="icon-question" @click="centerDialogVisible = true">
      <button class="subscribe-button" @click="stripeCheckout">Subscribe</button>
    </footer>

    <el-dialog
      v-model="centerDialogVisible"
      title="Usage Rules for New Users"
      width="600px"
      align-center
      :style="{ fontSize: '18px', fontFamily: 'alibaba-heavy, sans-serif' }"
    >
      <span style="display: block; font-size: 18px; padding: 20px; font-family: 'alibaba-light', sans-serif;">
        New users receive 3 initial uses. Each week, they receive 1 additional use, up to a maximum of 3 uses. If you need more uses, you can subscribe to our app.
      </span>
      <template #footer>
        <div class="dialog-footer" style="font-size: 18px; font-family: 'alibaba-light', sans-serif;">
          <el-button @click="centerDialogVisible = false" style="font-size: 18px; ">Cancel</el-button>
          <el-button type="primary" @click="stripeCheckout" style="font-size: 18px; background-color: #004080; border-color: #004080; ">
            Subscribe
          </el-button>
        </div>
      </template>
    </el-dialog>


    <el-dialog v-model="showLoginModal" width="40%" @close="handleCloseModal">
      <template #header>
        {{ isLogin ? 'Login' : 'Register' }}
      </template>
      <el-form :model="form" :rules="rules" ref="form" label-width="100px">
        <el-form-item label="Username" prop="username">
          <el-input v-model="form.username" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Password" prop="password">
          <el-input type="password" v-model="form.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item v-if="!isLogin" label="Email" prop="email">
          <el-input v-model="form.email" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item v-if="!isLogin" label="Country" prop="country">
          <el-input v-model="form.country" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="handleSubmit">{{ isLogin ? 'Login' : 'Register' }}</el-button>
          <el-button type="text" @click="switchMode">{{ isLogin ? 'Go to Register' : 'Go to Login' }}</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
  <stripe-checkout
          ref="checkoutRef"
          mode="payment"
          :pk="publishableKey"
          :line-items="lineItems"
          :success-url="successURL"
          :cancel-url="cancelURL"
          @loading="v => loading = v"
        />
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { ArrowDown } from '@element-plus/icons-vue';
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import { ref } from 'vue';

export default {
  data() {
    
    return {
      apiUrl: `${process.env.VUE_APP_API_URL}/flicker`,
      showDropdown: false,
      form: {
        username: '',
        password: '',
        email: '',
        country: ''
      },
      rules: {
        username: [
          { required: true, message: 'Please enter your username', trigger: 'blur' },
          { min: 3, max: 15, message: 'Length should be 3 to 15 characters', trigger: 'blur' }
        ],
        password: [
          { required: true, message: 'Please enter your password', trigger: 'blur' },
          { min: 6, message: 'Password should be at least 6 characters', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'Please enter your email', trigger: 'blur' },
          { type: 'email', message: 'Please enter a valid email address', trigger: 'blur' }
        ],
        country: [
          { required: true, message: 'Please enter your country', trigger: 'blur' }
        ]
      },
      num: 0, // Initial value for uses left
      publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      
      lineItems: [
        {
          price: 'price_1PcPNaBXSKReLyHrVQ8O08LV', // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      successURL: window.location.origin + '/paymentsuccess',
      cancelURL: window.location.origin + '/paymentcancel',
      loading: false,
      centerDialogVisible: false, // Add this line
    };
  },
  computed: {
    ...mapState(['isLoggedIn', 'username', 'showLoginModal', 'isLogin']),
  },
  created() {
    
    // 检查 localStorage 中是否有用户信息
      const userToken = localStorage.getItem('userToken');
      const username = localStorage.getItem('username');

      if (userToken && username) {
          // 如果有，则恢复登录状态
          this.setLoginStatus(true);
          this.setUsername(username);
        }// Fetch number of uses when component is created

      this.fetchNum(); // Fetch number of uses when component is created
    },
  methods: {
    ...mapActions(['toggleLoginModal', 'setLoginMode', 'setLoginStatus', 'setUsername', 'resetForm']),
    switchMode() {
      this.setLoginMode(!this.isLogin);
    },
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.isLogin ? this.login() : this.register();
        } else {
          console.log('Submit error!');
          return false;
        }
      });
    },
    login() {
      fetch(`${this.apiUrl}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: this.form.username,
          password: this.form.password
        })
      })
      .then(response => response.json())
      .then(data => {
        if (data.token) {
          localStorage.setItem('userToken', data.token);
          localStorage.setItem('username', data.username);
          localStorage.setItem('userid', data.userid);
          
          console.log(data.username)
          console.log(data.userid)

          this.setLoginStatus(true);
          this.setUsername(this.form.username);
          this.toggleLoginModal(false);
          this.fetchNum(); // Fetch number of uses after successful login
          this.$message.success('Login successful!');
        } else {
          this.$message.error(data.message || 'The username or password you entered is incorrect!');
        }
      })
      .catch(error => {
        console.error('Login error:', error);
        this.$message.error('Login failed due to server error!');
      });
    },
    logout() {
      try {
        localStorage.removeItem('userToken');
        localStorage.removeItem('username');
        localStorage.removeItem('userid');
        localStorage.removeItem('remaining_writes');

        this.setLoginStatus(false);
        this.setUsername('');
        this.$router.push('/');
        this.$message.success('Logout successful!');
      } catch (error) {
        console.error('Logout failed:', error);
        this.$message.error('Logout failed. Please try again.');
      }
    },
    register() {
      fetch(`${this.apiUrl}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.form)
      })
      .then(response => response.json())
      .then(data => {
        if (data.token) {
          localStorage.setItem('userToken', data.token);
          localStorage.setItem('username', data.username);
          localStorage.setItem('userid', data.userid);
          
          console.log(data.username)
          console.log(data.userid)

          this.setLoginStatus(true);
          this.setUsername(this.form.username);
          this.toggleLoginModal(false);
          this.fetchNum(); // Fetch number of uses after successful registration
          this.$message.success('Registration successful!');
        } else {
          this.$message.error(data.message || 'Registration failed!');
        }
      })
      .catch(error => {
        console.error('Registration error:', error);
        this.$message.error('Registration failed due to server error!');
      });
    },
    handleCommand(command) {
      if (command === 'logout') {
        this.logout();
      } else if (command === 'profile') {
        // Handle profile action
      } else if (command === 'settings') {
        // Handle settings action
      }
    },
    handleCloseModal() {
      this.toggleLoginModal(false);
      this.resetForm();
    },
    fetchNum() {
      const token = localStorage.getItem('userToken'); // Assuming the token is stored in local storage
      if (!token) {
        console.error('No token available');
        return;
      }

      fetch(`${this.apiUrl}/token_verify`, { // Endpoint that uses TokenValidationHandler
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: token })
      })
      .then(response => response.json())
      .then(data => {
        if (data.status === 'error') {
          this.$message.error(data.message || 'Failed to fetch data.');
        } else {
          this.num = data.data.remaining_writes; // Set num to remaining_writes from the response
          localStorage.setItem('remaining_writes', data.data.remaining_writes);
        }
      })
      .catch(error => {
        console.error('Error fetching remaining writes:', error);
        this.$message.error('Failed to fetch remaining writes due to a server error.');
      });
    },
    stripeCheckout() {
      console.log(this.publishableKey)
      this.$refs.checkoutRef.redirectToCheckout();
    },
    openDialog() {
      this.centerDialogVisible = true;
    },
    closeDialog() {
      this.centerDialogVisible = false;
    }
  },
  components: {
    ArrowDown,
    StripeCheckout,
  }
};
</script>


<style lang="scss" scoped>
@import '@/assets/iconfont/iconfont.css';

@font-face {
  font-family: 'alibaba-heavy';
  src: url('~@/assets/fonts/AlibabaPuHuiTi-2-105-Heavy.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'alibaba-light';
  src: url('~@/assets/fonts/AlibabaPuHuiTi-2-45-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

input:focus, input:hover {
  outline: none;
}

body, html {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: black;
}

.nuxt-devtools-panel {
  display: none !important;
}

.ai-slider-page {
  width: 100%;
  height: 100vh;
  border-radius: 2%;
  background-image: url(./assets/images/bg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 50px;
    z-index: 1000;

    .logo {
      position: absolute;
      top: 70%;
      left: 3%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;

      .icon-react {
        margin-right: 15px;
        font-weight: bold;
        font-size: 36px;
        color: #272636;
      }

      .logo-name {
        font-size: 27px;
        color: #212832;
        font-family: 'alibaba-heavy', sans-serif;
      }
    }

    .btn-login {
      position: absolute;
      top: 70%;
      right: 3%;
      transform: translateY(-50%);
      padding: 3px 15px;
      border: 1px solid #c6cbd3;
      border-radius: 3px;
      font-size: 18px;
      color: #888a8d;
      font-family: 'alibaba-light', sans-serif;
      cursor: pointer;

      &:hover {
        color: rgb(77, 74, 74);
      }
    }
  }
}

.user-info {
  position: absolute;
  top: 70%;
  right: 3%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  padding: 5px 15px;
  border: 1px solid #c6cbd3;
  border-radius: 3px;
  font-size: 18px;
  color: #888a8d;
  font-family: 'alibaba-light', sans-serif;

  .el-dropdown-link {
    margin-left: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 95%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  font-family: 'alibaba-heavy', sans-serif;
  font-size: 18px; /* 调整字体大小 */
}

.footer .iconfont {
  margin-right: 10px;
}

.footer .footer-text {
  font-size: 24px; /* 调整字体大小 */
}

.footer .subscribe-button {
  margin-left: 30px; /* 设置按钮和文字之间的间距 */
  padding: 10px 20px; /* 设置按钮的内边距 */
  font-size: 18px; /* 调整按钮字体大小 */
  background-color: #004080; /* 按钮背景颜色 */
  color: white; /* 按钮文字颜色 */
  border: none; /* 移除按钮边框 */
  border-radius: 5px; /* 设置按钮圆角 */
  cursor: pointer;
}

.footer .subscribe-button:hover {
  background-color: #235587; /* 按钮悬停时的背景颜色 */
}

.footer .icon-question {
  margin-left: 10px;
  width: 24px; /* 设置图片宽度 */
  height: 24px; /* 设置图片高度 */
}

</style>
