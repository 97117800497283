import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';


const routes: Array<RouteRecordRaw> = [
    { path: '/', component: () => import('@/components/HomeIndex.vue') },
    { 
        path: '/createPowerpoint', 
        component: () => import('@/components/CreatePowerpoint.vue'), 
        meta: { requiresAuth: true } 
    },
    { 
        path: '/appLoading', 
        component: () => import('@/components/AppLoading.vue'), 
        meta: { requiresToken: true } 
    },
    { 
        path: '/paymentcancel', 
        component: () => import('@/components/PaymentCancel.vue'), 
        meta: { requiresToken: true, serverOnly: true } 
    },
    { 
        path: '/paymentsuccess', 
        component: () => import('@/components/PaymentSuccess.vue'), 
        meta: { requiresToken: true, serverOnly: true } 
    }

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
